import { defineAsyncComponent, markRaw } from 'vue';

const AboutMe = markRaw(defineAsyncComponent(() => import('@/views/MXEditor/editor/AboutMe.vue')));
const AboutMeHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/AboutMeHelp.vue'))
);
const AgentProfileImage = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/AgentProfileImage.vue'))
);
const ProfileImageHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/ProfileImageHelp.vue'))
);
const BannerImage = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/BannerImage.vue'))
);
const BannerImageHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/BannerImageHelp.vue'))
);
const ContactMeHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/ContactMeHelp.vue'))
);
const DesignationsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/DesignationsHelp.vue'))
);
const JobsListing = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/JobsListing.vue'))
);
const JobsHelp = markRaw(defineAsyncComponent(() => import('@/views/MXEditor/help/JobsHelp.vue')));
const LanguagesHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/LanguagesHelp.vue'))
);
const MapsHelp = markRaw(defineAsyncComponent(() => import('@/views/MXEditor/help/MapsHelp.vue')));
const MetaDescription = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/MetaDescription.vue'))
);
const TitleAndMetaHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/TitleAndMetaHelp.vue'))
);
const LocationDetails = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/LocationDetails.vue'))
);
const LocationDetailHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/LocationDetailsHelp.vue'))
);
const MissionStatement = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/MissionStatement.vue'))
);
const MissionStatementHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/MissionStatementHelp.vue'))
);
const PreferredLocations = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/PreferredLocations.vue'))
);
const PreferredLocationsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/PreferredLocationsHelp.vue'))
);
const AgencyDetailsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/AgencyDetailsHelp.vue'))
);
const OfficeHours = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/OfficeHours.vue'))
);
const OfficeHoursHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/OfficeHoursHelp.vue'))
);
const PageTitle = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/PageTitle.vue'))
);
const PhoneDetailsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/PhoneDetailsHelp.vue'))
);
const PhonePrefs = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/PhonePrefs.vue'))
);
const PhonePrefsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/PhonePrefsHelp.vue'))
);
const ProductsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/ProductsHelp.vue'))
);
const SimpleInsights = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/SimpleInsights.vue'))
);
const SimpleInsightsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/SimpleInsightsHelp.vue'))
);
const SocialMedia = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/SocialMedia.vue'))
);
const SocialHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/SocialHelp.vue'))
);
const TeamSettings = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/TeamSettings.vue'))
);
const TeamHelp = markRaw(defineAsyncComponent(() => import('@/views/MXEditor/help/TeamHelp.vue')));
const TeamMembers = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/TeamMembers.vue'))
);
const TeamMemberHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/TeamMemberHelp.vue'))
);
const OfficeAboutUs = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/OfficeAboutUs.vue'))
);
const OfficeAboutUsHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/OfficeAboutUsHelp.vue'))
);
const ReviewsPref = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/ReviewsPref.vue'))
);
const ReviewsPrefHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/ReviewsPrefHelp.vue'))
);
const DesktopLayout = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/DesktopLayout.vue'))
);
const DesktopLayoutHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/DesktopLayoutHelp.vue'))
);
const JobsTabUrl = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/JobsTabUrl.vue'))
);
const JobsTabUrlHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/JobsTabUrlHelp.vue'))
);
const LocationsImage = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/LocationsImage.vue'))
);
const LocationsImageHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/LocationsImageHelp.vue'))
);
const UpdateContent = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/editor/UpdateContent.vue'))
);
const UpdateContentHelp = markRaw(
	defineAsyncComponent(() => import('@/views/MXEditor/help/UpdateContentHelp.vue'))
);

const EditorViews = [
	{
		path: 'about-me',
		label: 'About Me',
		icon: 'user-pen',

		component: AboutMe,
		help: AboutMeHelp,
	},

	{
		path: 'agent-image',
		label: 'Agent Image',
		icon: 'image',

		component: AgentProfileImage,
		help: ProfileImageHelp,
	},
	{
		path: 'banner-image',
		label: 'Banner Image',
		icon: 'panorama',

		component: BannerImage,
		help: BannerImageHelp,
	},
	{
		path: 'contact-me',
		label: 'Contact Us',
		icon: 'card-account-mail-outline',

		help: ContactMeHelp,
	},
	{
		path: 'designations',
		label: 'Designations',
		icon: 'alphabetical',

		help: DesignationsHelp,
	},
	{
		path: 'jobs-listing',
		label: 'Jobs Listing',
		icon: 'magnifying-glass-dollar',

		component: JobsListing,
		help: JobsHelp,
	},
	{
		path: 'languages',
		label: 'Languages',
		icon: 'translate',

		help: LanguagesHelp,
	},
	{
		path: 'maps',
		label: 'Maps',
		icon: 'map',

		help: MapsHelp,
	},
	{
		path: 'meta-description',
		label: 'Meta Description',
		header: 'Page Meta Description',
		icon: 'computer',

		component: MetaDescription,
		help: TitleAndMetaHelp,
	},
	{
		path: 'location-details',
		label: 'Location Details',
		icon: 'universal-access',

		component: LocationDetails,
		help: LocationDetailHelp,
	},
	{
		path: 'mission',
		label: 'Mission',
		icon: 'bullseye',

		component: MissionStatement,
		help: MissionStatementHelp,
	},
	{
		path: 'preferred-locations',
		label: 'Preferred Locations',
		icon: 'city',

		component: PreferredLocations,
		help: PreferredLocationsHelp,
	},
	{
		path: 'agency-details',
		label: 'Agency Details',
		header: 'Name, Address, and Landmark',
		icon: 'format-color-text',

		help: AgencyDetailsHelp,
	},
	{
		path: 'office-hours',
		label: 'Office Hours',
		icon: 'clock',

		component: OfficeHours,
		help: OfficeHoursHelp,
	},
	{
		path: 'page-title',
		label: 'Page Title',
		icon: 'file-circle-info',

		component: PageTitle,
		help: TitleAndMetaHelp,
	},
	{
		path: 'phone-details',
		label: 'Phone Details',
		header: 'Non-editable Phone Details',
		icon: 'phone',

		help: PhoneDetailsHelp,
	},
	{
		path: 'phone-prefs',
		label: 'Phone Prefs',
		header: 'Phone Prefs',
		icon: 'phone',

		component: PhonePrefs,
		help: PhonePrefsHelp,
	},
	{
		path: 'products',
		label: 'Products',
		icon: 'package-variant',

		help: ProductsHelp,
	},

	{
		path: 'simple-insights',
		label: 'Simple Insights',
		icon: 'newspaper',

		component: SimpleInsights,
		help: SimpleInsightsHelp,
	},
	{
		path: 'social',
		label: 'Social Media',
		icon: 'thumbs-up',

		component: SocialMedia,
		help: SocialHelp,
	},
	{
		path: 'team',
		label: 'Team - General',
		icon: 'people-group',

		component: TeamSettings,
		help: TeamHelp,
	},
	{
		path: 'team-members',
		label: 'Team - Members',
		icon: 'user-plus',

		component: TeamMembers,
		help: TeamMemberHelp,
	},
	{
		path: 'office-about-us',
		label: 'About Us',
		icon: 'users',

		component: OfficeAboutUs,
		help: OfficeAboutUsHelp,
	},
	{
		path: 'reviews',
		label: 'Reviews',
		icon: 'star',

		component: ReviewsPref,
		help: ReviewsPrefHelp,
	},
	{
		path: 'desktop-layout',
		label: 'Desktop Layout',
		icon: 'table-pivot',

		component: DesktopLayout,
		help: DesktopLayoutHelp,
	},
	{
		path: 'jobs-tab-url',
		label: "We're Hiring Tab",
		icon: 'person',

		component: JobsTabUrl,
		help: JobsTabUrlHelp,
	},
	{
		path: 'locations-image',
		label: 'Locations Image',
		icon: 'panorama',

		component: LocationsImage,
		help: LocationsImageHelp,
	},
	{
		path: 'route-update-content',
		label: 'Update Content',
		icon: 'arrows-rotate',

		component: UpdateContent,
		help: UpdateContentHelp,
	},
];
EditorViews.sort((a, b) => a.label.localeCompare(b.label));

export { EditorViews };
