<template>
	<div>
		<div class="rounded-lg bg-white p-4 dark:bg-gray-800">
			<h3 class="text-2xl font-semibold leading-none text-gray-700 dark:text-gray-100">
				Add new note
			</h3>
			<LoadingSpinner v-if="saving" class="mt-16" />
			<Form
				v-else
				v-slot="{ meta }"
				class="mt-2 grid grid-cols-2 gap-x-5 gap-y-1"
				@submit="addNote"
			>
				<Field
					v-slot="{ handleChange, handleBlur, errors }"
					name="Category"
					:rules="categoryChosen"
				>
					<BaseSelect
						id="category"
						v-model="category"
						label="Category"
						:items="categories"
						show-label
						outlined
						@update:model-value="handleChange"
						@blur="handleBlur"
					>
						<template v-if="errors.length > 0" #message> {{ errors[0] }} </template>
					</BaseSelect>
				</Field>

				<Field v-slot="{ field, errors }" name="Note Text" rules="required">
					<TextFieldInput
						v-model="text"
						v-bind="field"
						label="Note text"
						placeholder="Note text"
					>
						<template v-if="errors.length > 0" #message> Note text required </template>
					</TextFieldInput>
				</Field>

				<TextFieldInput
					v-model="followUpDate"
					label="Follow-up Date"
					type="date"
					:min="minDate"
				/>

				<CheckboxInput v-model="serviceInterruption" name="serviceInterruption">
					Service Interruption
				</CheckboxInput>

				<BaseButton
					type="submit"
					color="primary"
					:disabled="!meta.dirty || !meta.valid"
					class="col-span-2"
				>
					Add Note
				</BaseButton>
			</Form>
		</div>

		<div v-if="loading" class="mt-4 rounded-md bg-white py-16 dark:bg-gray-800">
			<LoadingSpinner text="Loading notes..." />
		</div>

		<SortableTable
			v-else
			:headers="headers"
			:items="notes"
			title="Notes"
			item-unique-key="updated_at"
			show-pagination
			show-search
			show-title
			expand-all
			class="mt-4 rounded-lg bg-white p-4 dark:bg-gray-800"
		>
			<template #item.follow_up_by="{ follow_up_by }">
				{{ follow_up_by ? format(new Date(follow_up_by), 'yyyy-MM-dd') : '' }}
			</template>
			<template #item.followed_up_at="{ followed_up_at }">
				{{ followed_up_at ? format(new Date(followed_up_at), 'yyyy-MM-dd') : '' }}
			</template>
			<template #item.created_at="{ created_at }">
				{{ format(new Date(created_at), 'yyyy-MM-dd') }}
			</template>
			<template #item_expanded="{ text: textContent, service_failure }">
				<p class="mb-0 p-4">
					{{ textContent }}
					<span v-if="service_failure"> (Service Interruption) </span>
				</p>
			</template>
		</SortableTable>
	</div>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import { Form, Field } from 'vee-validate';
import { useRoute } from 'vue-router';
import { format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import SortableTable from '@/components/ui/SortableTable.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

function categoryChosen(value) {
	if (value === undefined) {
		return 'Category must be chosen';
	} else if (value.name === 'Category') {
		return 'Category must be chosen';
	}
	return true;
}

const agentID = ref(null);

const categories = ref([]);
const notes = ref([]);
const category = ref(null);
const text = ref(null);
const followUpDate = ref(null);
const serviceInterruption = ref(false);

const saving = ref(false);
const loading = ref(true);

async function getNotesData() {
	const result = await useApi(`api/agents/${agentID.value}/notes/categories/`, {
		message: 'There was  an issue retrieving your notes data. Please try again later.',
	}).json();
	const data = result?.data?.value ?? [];
	categories.value = data;
}

async function getNotes(page = 1) {
	const result = await useApi(`api/agents/${agentID.value}/notes/?page=${page}&page_size=100`, {
		message: 'There was  an issue retrieving your notes data. Please try again later.',
	}).json();
	notes.value = result?.data?.value ?? [];
}

const minDate = computed(() => format(new Date(), 'yyyy-MM-dd'));

const page = ref(1);
const reset = ref(null);

const headers = [
	{ text: 'Assigned To', value: 'assigned_to', filterable: true },
	{ text: 'Follow Up By', value: 'follow_up_by', filterable: true },
	{ text: 'Followed Up At', value: 'followed_up_at' },
	{ text: 'Created At', value: 'created_at' },
	{ text: 'User', value: 'created_by', filterable: true },
	{ text: 'Category', value: 'category', filterable: true },
];

async function addNote() {
	saving.value = true;
	try {
		let noteData = {
			category: category.value,
			service_failure: serviceInterruption.value,
			text: text.value,
		};

		if (followUpDate.value !== null) {
			noteData.follow_up_by = new Date(followUpDate.value).toISOString();
		}

		await useApi(`api/agents/${agentID.value}/notes/`, {
			message: `There was an issue saving that note! Please try again later.`,
		}).post(noteData);

		saving.value = false;
		reset.value = new Date();
		category.value = null;
		text.value = null;
		followUpDate.value = null;
		serviceInterruption.value = false;

		await fetchNotes();
	} finally {
		saving.value = false;
	}
}
async function fetchNotes() {
	loading.value = true;
	await getNotes(page.value);
	loading.value = false;
}

watch(
	() => page.value,
	(p, oldP) => {
		if (p !== oldP) {
			fetchNotes();
		}
	}
);
onMounted(async () => {
	agentID.value = useRoute().params.agentID;
	await getNotesData();
	await fetchNotes();
});
</script>

<style scoped module lang="scss">
.row {
	cursor: pointer;
}
.text {
	display: block;
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 0.875rem;
}
</style>
