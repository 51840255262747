<template>
	<div class="relative inline-block w-fit hover:z-40">
		<div
			:aria-describedby="tooltipId"
			@mouseenter="showTooltip()"
			@mouseleave="hideTooltip({ delay: true })"
			@focusin="showTooltip()"
			@focusout="hideTooltip({ delay: false })"
			@keydown.esc="hideTooltip({ delay: false })"
		>
			<slot />
		</div>

		<transition name="fade">
			<div
				v-show="showingTooltip"
				:id="tooltipId"
				role="tooltip"
				class="pointer-events-none absolute inset-0 z-30"
			>
				<div
					:class="[positionClasses, arrowMarginClass]"
					class="absolute h-4 w-4 rotate-45 bg-gray-950"
					aria-hidden="true"
				></div>
				<div
					:class="[positionClasses, textMarginClass]"
					class="absolute w-max max-w-xs whitespace-normal rounded-lg bg-gray-950 px-3 py-2 text-sm font-medium text-white"
				>
					{{ text }}
				</div>
			</div>
		</transition>
	</div>
</template>
<script setup>
import { computed, ref } from 'vue';

defineEmits(['click']);
const props = defineProps({
	position: {
		type: String,
		default: 'top',
	},
	text: {
		type: String,
		default: 'Tooltip',
	},
	tooltipId: {
		type: String,
		required: true,
	},
});

const showingTooltip = ref(false);
const tooltipTimeout = ref(null);

function showTooltip() {
	clearTimeout(tooltipTimeout.value);
	showingTooltip.value = true;
}

function hideTooltip({ delay }) {
	if (delay) {
		tooltipTimeout.value = setTimeout(() => {
			showingTooltip.value = false;
		}, 500);
	} else {
		clearTimeout(tooltipTimeout.value);
		showingTooltip.value = false;
	}
}

const positionClasses = computed(() => {
	return {
		top: 'bottom-full left-1/2 -translate-x-1/2',
		right: 'left-full top-1/2 -translate-y-1/2',
		bottom: 'left-1/2 top-full -translate-x-1/2',
		left: 'right-full top-1/2 -translate-y-1/2',
	}[props.position];
});
const textMarginClass = computed(() => {
	return {
		top: 'mb-1',
		right: 'ml-1',
		bottom: 'mt-1',
		left: 'mr-1',
	}[props.position];
});
const arrowMarginClass = computed(() => {
	return {
		top: 'mb-0.5',
		right: 'ml-0.5',
		bottom: 'mt-0.5',
		left: 'mr-0.5',
	}[props.position];
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
