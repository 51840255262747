import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

export default defineStore('agent/locations', () => {
	const VueRoute = useRoute();
	const agentLocations = ref(null);
	const lastGetAgentId = ref(null);

	async function get(agentId = VueRoute.params.agentID) {
		lastGetAgentId.value = agentId;
		agentLocations.value = null;
		const result = await useApi(`api/agents/${agentId}/locations/`, {
			message: 'There was an issue retrieving the job listings. Please try again later.',
		}).json();
		agentLocations.value = result.data?.value;
	}

	async function ensureLocations(agentId = VueRoute.params.agentID) {
		if (lastGetAgentId.value === agentId || agentLocations.value) {
			return;
		}
		await get(agentId);
	}

	async function updateJobListing(officeAssociateId, url) {
		try {
			const url_override = url ? new URL(url).href : null;
			const response = await useApi(
				`api/agents/${VueRoute.params.agentID}/locations/${officeAssociateId}/jobs/`,
				{
					message:
						'There was an issue with updating your job listing. Please try again later.',
				}
			)
				.post({ url_override })
				.json();
			const jobListing = response.data?.value;
			const agentLocation = agentLocations.value.find(
				location => location.office_associate_id === officeAssociateId
			);
			if (agentLocation) {
				agentLocation.job_listing = jobListing;
			}
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function crawlJobs(officeAssociateId) {
		try {
			const response = await useApi(
				`api/agents/${VueRoute.params.agentID}/locations/${officeAssociateId}/jobs/crawl/`,
				{
					message:
						'There was an issue with updating your job listing. Please try again later.',
				}
			)
				.post({})
				.json();
			const jobListing = response.data?.value;
			const agentLocation = agentLocations.value.find(
				location => location.office_associate_id === officeAssociateId
			);
			if (agentLocation) {
				agentLocation.job_listing = jobListing;
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	return {
		agentLocations,

		get,
		ensureLocations,
		updateJobListing,
		crawlJobs,
	};
});
